import {Button, Pagination, Card, CardImage} from '@elanco/component-library-v2'
import {useInfiniteQuery} from 'react-query'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useContext, useEffect, useState} from 'react'
import Link from 'next/link'
import {SkeletonLoader} from '@/_new-code/products/flexible-web-toolkit/components/skeleton-loader'
import {pushToDataLayer} from '@/utils/analytics'
import type {
	ArticleConfigContentItem,
	Block,
	TaxonomySpeciesContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {fetchListerItems} from '@/fetchers/fetchListerItems'
import type {ListerItemsQueryKey} from '@/_new-code/products/flexible-web-toolkit/blocks/module-coupon-lister/types'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {GlobalContext} from '@/components/BlockMapper/GlobalClientState'

export type ModuleArticleListerContentItem = IContentItem<{
	articleListerTitle: Elements.TextElement
	initialArticlesNumber: Elements.NumberElement
	subsequentArticlesNumber: Elements.NumberElement
	buttonText: Elements.TextElement
	nextButtonText: Elements.TextElement
	prevButtonText: Elements.TextElement
	usePagination: Elements.MultipleChoiceElement
	itemsOrderingOptions: Elements.MultipleChoiceElement
}>

export const ModuleArticleListerBlock: Block<
	ModuleArticleListerContentItem
> = ({block, page, globalConfig}) => {
	const router = useRouter()
	const gs = useContext(GlobalContext)
	const authState = useAuth()

	const [pageNumber, setPageNumber] = useState(0)
	const speciesCodename =
		'filterByTaxonomy' in page.elements
			? (
					page.elements
						.filterByTaxonomy as Tersed<TaxonomySpeciesContentItem>[]
				)[0]?.elements.species[0]?.codename
			: undefined

	const speciesFilter = [
		{
			taxonomy: 'species_taxonomy',
			terms: [speciesCodename],
		},
	]
		.map((item) => ({
			...item,
			terms: item.terms.filter(Boolean),
		}))
		.filter((item) => item.terms.length > 0)
	const [filters, setFilters] = useState<
		{
			taxonomy: string
			terms: string[]
		}[]
	>()

	const {buttonText} = block.elements
	const nextButtonText = block.elements.nextButtonText || 'Next'
	const prevButtonText = block.elements.prevButtonText || 'Prev'
	const subsequentArticlesNumber =
		block.elements.subsequentArticlesNumber ?? 6
	const initialItemsNumber = block.elements.initialArticlesNumber ?? 6

	useEffect(() => {
		const updatedFilters = Object.entries(
			gs?.[0]?.articlesFilterOptions ?? {}
		).map(([taxonomy, options]) => ({
			taxonomy: taxonomy === 'species' ? 'species_taxonomy' : taxonomy,
			terms: (() => {
				if ('checkboxOptions' in options) {
					return options.checkboxOptions
						.filter((opt) => opt.checked)
						.map((opt) => opt.codename)
				}
				return [options.value]
			})(),
		}))
		setFilters([...speciesFilter, ...updatedFilters])
		// eslint-disable-next-line react-hooks/exhaustive-deps -- intentional as speciesFilter is static and doesn't change after the initial render, so it's safe to omit it from the dependency array.
	}, [gs])

	const usePagination = block.elements.usePagination[0]?.codename === 'yes'

	const {isLoading, isFetching, fetchNextPage, data} = useInfiniteQuery({
		queryKey: [
			'moduleArticleLister',
			{
				codename: 'config_article',
				locale: router.locale ?? '',
				filters: JSON.stringify(filters),
				orderBy:
					block.elements.itemsOrderingOptions[0]?.codename ??
					undefined,
				hideElementCodename: 'hide_from_lister',
				skip: 0,
				limit: initialItemsNumber,
			},
		] as ListerItemsQueryKey,
		queryFn: (opts) =>
			fetchListerItems<Tersed<ArticleConfigContentItem>>(opts),
		getNextPageParam: () => {
			return pageNumber
		},
		staleTime: 10 * (60 * 1000),
		cacheTime: 15 * (60 * 1000),
		enabled: globalConfig.elements.cdcKey
			? (authState.ready && !authState.authenticated) ||
				(authState.ready &&
					authState.authenticated &&
					Boolean(authState.userDetails.id))
			: true,
	})

	const totalCount = data?.pages[0]?.pagination.totalCount ?? 0

	function getDisplayedItemCount(): number {
		const rawTotalItems =
			(data?.pages[0]?.pagination.count ?? 0) +
			pageNumber * initialItemsNumber

		return rawTotalItems > totalCount ? totalCount : rawTotalItems
	}

	function getHasNextPage(): boolean {
		const displayedItems = getDisplayedItemCount()

		return displayedItems < totalCount
	}

	return (
		<div>
			{isFetching || !data ? (
				<div className="mx-auto my-2 grid max-w-screen-xl grid-cols-1 gap-10 px-3 sm:grid-cols-2 sm:px-0 md:grid-cols-3">
					<SkeletonLoader
						amount={
							!data
								? initialItemsNumber
								: subsequentArticlesNumber
						}
					/>
				</div>
			) : null}
			{!isLoading && data ? (
				<div className="mx-auto flex max-w-screen-xl flex-wrap justify-center">
					{data.pages.map((pageData) => {
						const items = pageData.items

						return items
							.filter(authState.checkUserRoleForBlock)
							.map((item) => (
								<div
									className="my-2 flex w-full flex-wrap justify-center sm:w-1/2 md:w-1/3"
									key={item.system.id}
								>
									<Card
										href={item.elements.articleSlug}
										title={
											item.elements.articleSlug ? (
												<Link
													href={
														item.elements
															.articleSlug
													}
													prefetch={false}
													rel="noreferrer"
												>
													{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- Necessary for next/link */}
													<a
														onClick={() => {
															pushToDataLayer({
																event: 'card_click',
																element_text:
																	item
																		.elements
																		.title,
																link_url:
																	item
																		.elements
																		.articleSlug,
															})
														}}
													>
														{item.elements.title}
													</a>
												</Link>
											) : null
										}
									>
										{item.elements.thumbnailImage[0] ? (
											<CardImage
												alt={
													item.elements
														.thumbnailImage[0]
														.description
												}
												className="h-44 object-cover"
												src={
													item.elements
														.thumbnailImage[0].url
												}
											/>
										) : null}
									</Card>
								</div>
							))
					})}
				</div>
			) : null}
			{isFetching ? (
				<div className="mx-auto my-2 grid max-w-screen-xl grid-cols-1 gap-10 px-3 sm:grid-cols-2 sm:px-0 md:grid-cols-3">
					<SkeletonLoader
						amount={
							!data
								? initialItemsNumber
								: subsequentArticlesNumber
						}
					/>
				</div>
			) : null}
			{usePagination ? (
				<Pagination
					activeIndex={pageNumber + 1}
					amountOfPages={Math.ceil(totalCount / initialItemsNumber)}
					nextButtonText={<span>{nextButtonText}</span>}
					onPageChange={async () => {
						await fetchNextPage()
						setPageNumber((cur) => cur + 1)
					}}
					pagesToShow={3}
					prevButtonText={<span>{prevButtonText}</span>}
					showLastPage
				/>
			) : (
				buttonText &&
				getHasNextPage() && (
					<div
						className="my-10 text-center"
						data-kontent-element-codename="button_text"
					>
						<Button
							onClick={async () => {
								await fetchNextPage()
								setPageNumber((cur) => cur + 1)
								pushToDataLayer({
									event: 'cta_click',
									cta_name: buttonText,
									cta_category: 'button',
								})
							}}
							type="button"
						>
							{buttonText}
						</Button>
					</div>
				)
			)}
		</div>
	)
}
