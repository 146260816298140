import {ParasiteTrackerMap as Map} from '@elanco/component-library-v2'
import {useQuery} from 'react-query'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {fetchParasiteCases} from '@/fetchers/fetchParasiteTrackerCases'
import {env} from '@/utils/env/client.mjs'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {MAP_STYLES} from '@/_new-code/products/disease-map/parasite-tracker/parasite-tracker-map/map-styles'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'
import type {LegendsContentItem} from '../parasite-tracker-legend'
import {LegendsBlock} from '../parasite-tracker-legend'
import type {LatLng} from '../parasite-tracker-module'

const HeatmapAndMarkersMapping = {
	heatmap_and_markers: false,
	heatmap: true,
}

export type ParasiteTrackerMapContentItem = IContentItem<{
	parasiteType: Elements.MultipleChoiceElement
	mapType: Elements.MultipleChoiceElement
	heatmapOpacity: Elements.NumberElement
	markerAccentColour: Elements.TextElement
	latitude: Elements.NumberElement
	longitude: Elements.NumberElement
	zoomLevel: Elements.NumberElement
	startDate: Elements.DateTimeElement
	singleCaseTitle: Elements.TextElement
	singleCaseSubtitle: Elements.TextElement
	multipleCaseTitle: Elements.TextElement
	multipleCaseSubtitle: Elements.TextElement
	placeholderText: Elements.TextElement
	mapLegend: Elements.LinkedItemsElement<LegendsContentItem>
	errorMessage: Elements.TextElement
	radius: Elements.NumberElement
}>

export const ParasiteTrackerMapBlock: ExtendedBlock<
	ParasiteTrackerMapContentItem,
	{
		error: boolean
		setError: (error: boolean) => void
		searchedCases: unknown[] | null
		searchedLocation: LatLng | null
		heatmapJson: string
		searchApi: (location: LatLng) => Promise<void>
		reportedCases: string
	}
> = ({
	block: {
		elements: {
			zoomLevel = 6,
			longitude = 0,
			latitude = 52.5555,
			placeholderText,
			heatmapOpacity = 1,
			singleCaseTitle,
			singleCaseSubtitle,
			multipleCaseTitle,
			multipleCaseSubtitle,
			markerAccentColour,
			mapLegend,
			mapType = [
				{
					codename: 'heatmap_and_markers',
					name: 'Heatmap and Markers',
				},
				{
					codename: 'heatmap',
					name: 'Heatmap',
				},
			],
			parasiteType = [
				{
					codename: 'lungworm',
					name: 'Lungworm',
				},
			],
			errorMessage,
			startDate,
		},
	},
	setError,
	searchedCases,
	searchedLocation,
	heatmapJson = '/counties.json',
	error,
	searchApi,
	...context
}) => {
	const {
		isLoading,
		isFetching,
		data: heatmapCases,
	} = useQuery(
		[
			'parasiteTrackerCases',
			{
				parasiteType: parasiteType[0]?.codename,
				parasiteStartDate: startDate ?? undefined,
			},
		],
		fetchParasiteCases,
		{
			refetchOnWindowFocus: false,
			cacheTime: 15 * (60 * 1000),
		}
	)

	return (
		<div
			className="relative"
			data-kontent-element-codename="parasite_tracker_map"
		>
			{isLoading || isFetching ? (
				<div className="flex h-[350px] w-full animate-pulse items-center justify-center rounded bg-elanco-blue bg-opacity-20 p-5 sm:h-[700px]">
					<LoadingSpinner theme="blue" />
				</div>
			) : null}

			{!isFetching && !isLoading && (
				<Map
					apiKey={env.NEXT_PUBLIC_MAP_API_KEY}
					defaultLat={latitude}
					defaultLng={longitude}
					errorMessage={errorMessage}
					hasError={error}
					heatmapJson={heatmapJson}
					heatmapLocationCases={heatmapCases}
					heatmapOnly={
						HeatmapAndMarkersMapping[
							mapType[0]
								?.codename as keyof typeof HeatmapAndMarkersMapping
						]
					}
					heatmapOpacity={heatmapOpacity}
					initialZoom={zoomLevel}
					mapStyles={MAP_STYLES}
					markerColor={markerAccentColour}
					multipleCaseSubTitle={multipleCaseSubtitle}
					multipleCaseTitle={multipleCaseTitle}
					onSearchApi={searchApi}
					placeholderText={placeholderText || 'Search'}
					searchLocation={searchedLocation}
					searchedCasesData={searchedCases}
					setError={setError}
					singleCaseSubTitle={singleCaseSubtitle}
					singleCaseTitle={singleCaseTitle}
				>
					{mapLegend[0] ? (
						<LegendsBlock block={mapLegend[0]} {...context} />
					) : null}
				</Map>
			)}
		</div>
	)
}
