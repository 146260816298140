export const getPinSvgString = (
	numOfCases: number,
	markerColor: string
): string => `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="45" viewBox="0 0 40 60">
  <g id="Group_16846" data-name="Group 16846" transform="translate(-1409 -316)">
  <path id="Path_11480" data-name="Path 11480" d="M20,0A20.082,20.082,0,0,1,40,20.164C40,31.3,20,60,20,60S0,31.3,0,20.164A20.082,20.082,0,0,1,20,0Z" transform="translate(1409 316)" fill="${markerColor}"/>
  <circle id="Ellipse_91498" data-name="Ellipse 91498" cx="16" cy="16" r="16" transform="translate(1413 320)" fill="#fff"/>
  <text font-size="12px" stroke="black" text-anchor="middle" transform="translate(1429 341)">${numOfCases}</text>
  </g>
  </svg>`

export const getClusterSvgString = (
	markerColor: string,
	clusterCount: number
): string => `
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 46 46">
        <g id="Group_16843" data-name="Group 16843" transform="translate(-1462 -312)">
          <g id="Ellipse_91499" data-name="Ellipse 91499" transform="translate(1464 314)" fill="#fff" stroke="${markerColor}" stroke-width="2">
            <circle cx="21" cy="21" r="21" stroke="none"/>
            <circle cx="21" cy="21" r="22" fill="none"/>
			<text font-size="12px" fill="black" stroke-width="1" stroke="black" x="21" y="26" text-anchor="middle">${clusterCount}</text>
          </g>
        </g>
      </svg>`

export const getInfoWindowContent = (
	numOfCases: number,
	singleCaseTitle: string,
	singleCaseSubtitle: string,
	multipleCaseTitle: string,
	multipleCaseSubtitle: string,
	postalCode: string
): string => `<h4 class="text-s font-bold">${
	numOfCases <= 1 ? singleCaseTitle : multipleCaseTitle
}</h4>
<span class="text-xs block my-2.5">${numOfCases} ${
	numOfCases <= 1 ? singleCaseSubtitle : multipleCaseSubtitle
}</span>
<span class="text-s block font-bold">${postalCode}</span>`
