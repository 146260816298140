import {useEffect, useState, useRef} from 'react'
import {Button} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import type {DistributorAPIResponse} from '@/pages/api/distributors'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {ExtendedBlock, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {DistributorCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/distributor-card'

export type DistributorCardListerContentItem = IContentItem<{
	initialItemsNumber: Elements.NumberElement
	subsequentItemsNumber: Elements.NumberElement
	buttonText: Elements.TextElement
	paginationString: Elements.TextElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const DistributorCardListerBlock: ExtendedBlock<
	DistributorCardListerContentItem,
	{
		block: {
			elements: {directoryItems?: Tersed<DistributorCardContentItem>[]}
		}
		totalItemCount?: number
	}
> = ({
	block: {
		elements: {
			buttonText,
			directoryItems = [],
			initialItemsNumber,
			paginationString,
			snippetSpacingSpacing,
			subsequentItemsNumber,
		},
	},
	totalItemCount = 0,
	BlockMapper,
	...context
}) => {
	const {locale = ''} = useRouter()

	const componentSpacing =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, snippetSpacingSpacing may be null
		spacing[snippetSpacingSpacing[0]?.codename as keyof typeof spacing] ||
		spacing.none

	// Setup local state for button and product list
	const firstPageChange = useRef(true)
	const [page, setPage] = useState(-1)
	const [requestMade, setRequestMade] = useState(false)
	const [showMore, setShowMore] = useState(
		directoryItems.length < totalItemCount
	)
	const [cardArray, setCardArray] = useState(directoryItems)

	const pagination = paginationString
		.replace(/{{X}}/, cardArray.length.toString())
		.replace(/{{Y}}/, totalItemCount.toString())

	// Button handler
	const nextPage = (): void => {
		setPage(page + 1)
	}

	useEffect(() => {
		if (firstPageChange.current) {
			firstPageChange.current = false
			return
		}
		// Call the API endpoint and add data to cardArray
		const endpoint = `/api/distributors?page=${page}
		&len=${subsequentItemsNumber}&skip=${initialItemsNumber}&locale=${locale}`
		fetch(endpoint)
			.then(
				(response) =>
					response.json() as unknown as DistributorAPIResponse
			)
			.then((res) => {
				if (!res.success) {
					return
				}

				const {data} = res

				if (data.length) {
					if (totalItemCount <= data.length + cardArray.length) {
						setShowMore(false)
					}

					setCardArray([...cardArray, ...data])
				} else if (requestMade) {
					setShowMore(false) // If request returns no results hide button
				}
			})
			.catch((err) => {
				// eslint-disable-next-line no-console -- TODO: client side logging
				console.log('Product fetch error:', err)
			})
		setRequestMade(true)
	}, [
		cardArray,
		initialItemsNumber,
		locale,
		page,
		requestMade,
		subsequentItemsNumber,
		totalItemCount,
	])

	return (
		<div
			className={`mx-auto ${componentSpacing}`}
			style={{maxWidth: '1200px'}}
		>
			<p className="mb-4 text-center font-bold sm:text-left">
				{pagination}
			</p>
			<div className="flex flex-col">
				{cardArray.map((item) => {
					return (
						<div className="w-full" key={item.system.id}>
							<BlockMapper
								blocks={[item]}
								key={item.system.id}
								{...context}
							/>
						</div>
					)
				})}
			</div>
			{Boolean(showMore) && Boolean(buttonText) && (
				<div className="my-10 w-1 text-center">
					<Button onClick={nextPage} type="button">
						{buttonText}
					</Button>
				</div>
			)}
		</div>
	)
}
